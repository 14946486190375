import commonState from '@/common/store/state'

import { entity, entitySingle } from '../entity'

const customState = {
  entity,
  entitySingle,
  sortData: { column: 'name', direction: 'asc' },
  userSubscriptions: {
    key: '',
    show: false,
    subscriptions: [],
    lists: [],
    isFetching: false
  }
}

export default Object.assign(
  {},
  commonState,
  customState
)
