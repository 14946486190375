<template>
  <CModal
    :show="true"
    :no-close-on-backdrop="true"
    :centered="true"
    size="lg"
    color="info"
  >
    <Form
      :user="user"
      :subscriptions="subscriptions"
    />
    <List
      :user="user"
      :lists="lists"
      :fetching="isFetching"
    />
    <template #header>
      <h3 class="modal-title">User Subscriptions - {{ user.name }}</h3>
      <CButtonClose @click="onCancel" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="onCancel" color="success">Close</CButton>
    </template>
  </CModal>
</template>

<script>
import { mapState } from 'vuex'

import Form from './Form'
import List from './List'

import { entity } from '@/views/User/entity'

export default {
  name: 'UserSubscription',
  components: {
    Form,
    List
  },
  methods: {
    onCancel () {
      this.$store.commit(`${entity}/HIDE_USER_SUBSCRIPTIONS`)
    }
  },
  computed: {
    ...mapState({
      subscriptions: state => state[entity].userSubscriptions.subscriptions,
      user: state => state[entity].userSubscriptions.user,
      lists: state => state[entity].userSubscriptions.lists,
      isFetching: state => state[entity].userSubscriptions.isFetching
    })
  },
  mounted () {
    document.querySelector('body').classList.add('overflow-hidden')
    const { id: userId } = this.user
    this.$store.dispatch(`${entity}/loadSubscriptions`)
    this.$store.dispatch(`${entity}/loadUserSubscriptions`, { userId })
  },
  destroyed () {
    document.querySelector('body').classList.remove('overflow-hidden')
  }
}
</script>
