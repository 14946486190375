<template>
  <CModal
    :show="true"
    :no-close-on-backdrop="true"
    :centered="true"
    size="lg"
    color="info"
  >
    <template #header>
      <h3 class="modal-title">{{ $options.entitySingle }} Detail - {{ item.id }}</h3>
      <CButtonClose @click="hideForm" class="text-white"/>
    </template>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Name</label>
          <strong class="d-block border px-3 py-2">{{ item.name }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Email</label>
          <strong class="d-block border px-3 py-2">{{ item.email }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow v-if="hasSubscription">
      <CCol sm="12">
        <div class="form-group">
          <label>Total Subscriptions:</label>
          <strong class="px-3 py-2">{{ item.total_subscriptions }} </strong>
        </div>
      </CCol>
      <CCol v-if="item.current_subscription" sm="12">
        <label>Current Subscription:</label>
        <strong class="px-3 py-2">{{ item.current_subscription.name }} ({{ item.current_subscription.expiry_at }})</strong>
      </CCol>
    </CRow>
    <CRow class="mb-2">
      <CCol sm="12">
        <label class="d-block">Roles</label>
        <CBadge v-start-case color="primary" v-for="role in item.roles" :key="role.id">
          {{ role.name }}
        </CBadge>
      </CCol>
    </CRow>
    <template #footer>
      <CButton @click="hideForm" color="success">OK</CButton>
    </template>
  </CModal>
</template>

<script>
import startCase from 'lodash/startCase'
import { mapState } from 'vuex'
import { entity, entitySingle } from '../entity'

export default {
  name: 'Detail',
  entitySingle: startCase(entitySingle),
  computed: {
    ...mapState({
      item: state => state[entity].detail.data
    }),
    hasSubscription: function () {
      return !!this.item.roles.find(e => e.name === 'user')
    }
  },
  methods: {
    hideForm () {
      this.$store.commit(`${entity}/DETAIL_DATA`, null)
    }
  },
  mounted () {
    document.querySelector('body').classList.add('overflow-hidden')
  },
  destroyed () {
    document.querySelector('body').classList.remove('overflow-hidden')
  }
}
</script>
