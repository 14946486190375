<template>
  <div class="event-stake-form mb-3">
    <CRow>
      <CCol sm="12">
        <CSelect
          :value="item.subscription_id"
          name="subscription_id"
          label="Add Subscription"
          :options="subscriptions"
          placeholder="Select Subscription"
          @update:value="onInputChanged"
        />
      </CCol>
    </CRow>
    <CRow v-if="item.subscription_id === 2">
      <CCol sm="6">
        <CInput
          v-model="item.price"
          name="price"
          label="Cost *"
          placeholder="99"
          :isValid="!errors.price ? null : false"
          :invalidFeedback="errors.price"
          @change="onInputChanged"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.expiry_at"
          name="expiry_at"
          label="Expiry *"
          placeholder="01/31/2021"
          :isValid="!errors.expiry_at ? null : false"
          :invalidFeedback="errors.expiry_at"
          @change="onInputChanged"
        />
      </CCol>
    </CRow>
    <CRow v-if="!!item.subscription_id">
      <CCol sm="4">
        <CSpinner v-if="isAdding" color="primary" :grow="true" />
        <CButtonGroup v-if="!isAdding" size="sm">
          <CButton color="primary" @click="onAdd">Add</CButton>
          <CButton color="danger" @click="onCancel">Cancel</CButton>
        </CButtonGroup>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'UserSubscriptionForm',
  props: {
    user: Object,
    subscriptions: Array
  },
  data () {
    return {
      item: { subscription_id: null, price: '', expiry_at: '' },
      errors: {},
      isAdding: false
    }
  },
  methods: {
    onInputChanged (value, e) {
      const { target: { name } } = e
      this.item = { ...this.item, [name]: value }
      if (name === 'subscription_id' && value !== 2) {
        this.item.price = ''
        this.item.expiry_at = ''
      }
    },
    async onAdd () {
      this.isAdding = true
      this.errors = await this.$store.dispatch('users/addUserSubscription', { userId: this.user.id, data: this.item })
      this.isAdding = false
    },
    onCancel () {
      this.item.subscription_id = null
      this.item.price = ''
      this.item.expiry_at = ''
    }
  }
}
</script>
