import axios from '@/utils/axios'
import commonActions from '@/common/store/actions'

const rootDispatch = { root: true }
const customActions = {
  async loadUserSubscriptions ({ commit, dispatch }, { userId }) {
    commit('IS_FETCHING', { key: 'userSubscriptions', isFetching: true })
    const { type, payload } = await axios({ url: `user/${userId}/subscriptions`, method: 'GET' })
    if (type === 200) {
      commit('USER_SUBSCRIPTIONS_LISTS', payload.data)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
    commit('IS_FETCHING', { key: 'userSubscriptions', isFetching: false })
  },
  async loadSubscriptions ({ commit, dispatch }) {
    commit('IS_FETCHING', { key: 'userSubscriptions', isFetching: true })
    const { type, payload } = await axios({ url: 'subscriptions/subscriptionOptions', method: 'GET' })
    if (type === 200) {
      const subscriptionSelections = payload.map(e => (Object.assign({ value: e.id, label: e.name }, e)))
      commit('USER_SUBSCRIPTION_SELECTION', subscriptionSelections)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
    commit('IS_FETCHING', { key: 'userSubscriptions', isFetching: false })
  },
  async addUserSubscription ({ state, commit, dispatch }, { userId, data }) {
    const { type, payload } = await axios({ url: `users/${userId}/subscriptions`, method: 'POST', data })
    if (type === 200) {
      const { user } = state.userSubscriptions
      commit('SHOW_USER_SUBSCRIPTIONS', { user })
      dispatch('addNotification', { type: 'success', message: 'User subscription successfully added.' }, rootDispatch)
      dispatch('loadLists')
    } else if (type === 422) {
      const { errors } = payload
      const [firstKey] = Object.keys(errors)
      dispatch('addNotification', { type: 'error', message: errors[firstKey][0] }, rootDispatch)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  }
}

export default Object.assign(
  {},
  commonActions,
  customActions
)
