<template>
  <div class="lists-table">
    <CDataTable
      :hover="true"
      :striped="true"
      :border="true"
      :small="true"
      :fixed="true"
      :items="lists"
      :fields="fields"
      :dark="false"
      :loading="fetching"
    >
      <template #subscription="{item}">
        <td v-start-case>
          {{ item.subscription.name }}
        </td>
      </template>
      <template #is_current_subscription="{item}">
        <td class="text-center">
              <CBadge v-if="item.is_current_subscription" color="success">
                Current
              </CBadge>
            </td>
      </template>
    </CDataTable>
  </div>
</template>

<script>
export default {
  name: 'UserSubscriptionsList',
  props: {
    event: Object,
    lists: Array,
    fetching: Boolean
  },
  data () {
    return {
      items: this.lists,
      fields: [
        'subscription',
        { key: 'price', label: 'Cost' },
        { key: 'created_at', label: 'Subscription Date' },
        { key: 'expiry_at', label: 'Expiry Date' },
        { key: 'is_current_subscription', label: 'Current Subscription' }
      ]
    }
  }
}
</script>
