<template>
  <CForm autocomplete="off">
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.name"
          name="name"
          label="Name *"
          placeholder="Name"
          :isValid="!errors.name ? null : false"
          :invalidFeedback="errors.name"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.email"
          name="email"
          label="Email *"
          placeholder="Email"
          :isValid="!errors.email ? null : false"
          :invalidFeedback="errors.email"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.password"
          name="password"
          label="Password"
          placeholder="Password"
          :isValid="!errors.password ? null : false"
          :invalidFeedback="errors.password"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.password_confirmation"
          name="password_confirmation"
          label="Confirm Password"
          placeholder="Confirm Password"
          :isValid="!errors.password_confirmation ? null : false"
          :invalidFeedback="errors.password_confirmation"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import inputFields from '../inputFields'

export default {
  name: 'Form',
  props: {
    data: {
      type: Object,
      defaultValue: {}
    },
    errors: {
      type: Object,
      defaultValue: {}
    }
  },
  data () {
    const defaultFields = Object.keys(inputFields).reduce((data, key) => {
      const { defaultValue } = inputFields[key]
      data[key] = defaultValue
      return data
    }, {})
    const item = Object.assign({}, defaultFields, this.data || {})
    return {
      item
    }
  },
  methods: {
    preventSubmit (event) {
      event.preventSubmit()
      event.preventDefault()
      event.stopPropagation()
    },
    onInputChanged (value, e) {
      const { target: { name } } = e
      this.item = { ...this.item, [name]: value }
      this.onHandleChange()
    },
    onHandleChange () {
      this.$emit('itemChange', this.item)
    }
  }
}
</script>
