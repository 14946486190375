import commonMutations from '@/common/store/mutations'
import generateKey from '@/utils/generateKey'

const customMutations = {
  SHOW_USER_SUBSCRIPTIONS (state, payload) {
    const { user } = payload
    state.userSubscriptions = { ...state.userSubscriptions, key: generateKey(), show: true, user }
  },
  HIDE_USER_SUBSCRIPTIONS (state) {
    state.userSubscriptions = { ...state.userSubscriptions, show: false }
  },
  IS_FETCHING (state, payload) {
    const { key, isFetching } = payload
    state[key] = { ...state[key], isFetching }
  },
  USER_SUBSCRIPTIONS_LISTS (state, payload) {
    state.userSubscriptions = { ...state.userSubscriptions, lists: payload }
  },
  USER_SUBSCRIPTION_SELECTION (state, payload) {
    state.userSubscriptions = { ...state.userSubscriptions, subscriptions: payload }
  }
}

export default Object.assign(
  {},
  commonMutations,
  customMutations
)
